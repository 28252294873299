.title {
  input {
    font-size: 42px;
    font-weight: 900;
  }

  div {
    &:before,
    &:after {
      display: none;
    }
  }
}



.tags {
  margin: 5px 10px!important;
}

.editor {
  margin: 30px -30px;

  :global {
    .cm-s-easymde {
      border: 0;
      font-size: 22px;
    }
    .editor-toolbar {
      border: 0;
      background-color: rgb(0 0 0 / 2%);
    }
  }
}

.buttons {
  display: flex;

  button {
    margin-right: 15px;
  }
}


.select {
  display: flex;
  margin: 20px 0;
}

.imageContainer {
  display: flex;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageItem {
  position: relative;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  width: 200px;
  height: 200px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.imageDelete {
  position: absolute!important;
  bottom:0;
  width: 100%;
}

.Item {
  display: flex;
  margin-bottom: 20px;
}