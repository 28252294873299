.button {
  padding: 0 20px;
  margin: 0 0 0 20px;
  display: flex;
  height: 43px;
  border-radius: 5px;
  border: 1px solid #626262;

  @media screen and (max-width: 901px) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }

}
